<template>
    <div class="margintop">
      <div id="ItemContainer">
        <div id="TopbarContainer" calss="col-12" style="display: flex;">
          <h1 id="Title" class="px-1 my-auto col-6">Novidades</h1>
          <div class="my-auto text-right col-6">
            <v-btn title="Mais Recente." :id="'desc'" icon :color="order === 'desc' ? '#339933' : '#444'" v-on:click="ordering('d')">
              <v-icon>{{ icons.mdiSortDesc }}</v-icon>
            </v-btn>
            <v-btn title="Mais Antigo." :id="'asc'" icon :color="order === 'asc' ? '#339933' : '#444'" v-on:click="ordering('a')">
              <v-icon>{{ icons.mdiSortAsc }}</v-icon>
            </v-btn>
          </div>
        </div>
        <div id="ItemDisplay">
          <!--<v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="10"
              :server-items-length="totalItems"
              @pagination="paginate"
              :loading="loading"
              loading-text="Carregando..."
              :footer-props="{'page-text': '', 'items-per-page-all-text': '', 'items-per-page-text': '', 'disable-items-per-page': true, 'show-current-page': true}"
              class="elevation-2 justify-content-center"
          >
            <template v-slot:item.date="{ item }">
              <span>{{ translate(item.date) }}</span>
            </template>
          </v-data-table>-->
          <table class="table table-striped" v-if="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)">
            <thead>
              <tr>
                <th scope="col" class="col-md-4">Data</th>
                <th scope="col" class="col-md-8">Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in desserts" :key="item.title">
                <td>{{item.date !== "---" ? translate(item.date) : ""}}</td>
                <td>{{item.title}}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-striped" v-else>
            <thead>
              <tr>
                <th scope="col" class="col-md-4 text-center">Data<hr class="w-50 mx-auto">Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in desserts" :key="item.title">
                <td class="text-center">{{item.date !== "---" ? translate(item.date) : ""}}<hr class="w-50 mx-auto">{{item.title}}</td>
              </tr>
            </tbody>
          </table>
          <v-btn v-if="displaySeeMore"
                id="SeeMore"
                plain
                :loading="loading"
                elevation="0"
                color="#888"
                width="300"
                @click="handleLoadMore"
                class="mt-3">
                Ver mais
          </v-btn>
        </div>
      </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mdiSortCalendarDescending } from '@mdi/js';
import { mdiSortCalendarAscending } from '@mdi/js';

  export default {
    data () {
      return {
        order: 'desc',
        seeMore: false,
        page: 1,
        loading: false,
        icons: {
          mdiSortAsc: mdiSortCalendarAscending,
          mdiSortDesc: mdiSortCalendarDescending,
        },
        headers: [
          {
            text: 'Data',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          { text: 'Descrição', value: 'title', sortable: false },
        ],
        desserts: [],
        totalItems: 0,
      }
    },
    methods: {
      paginate: function (page) {
        this.page = page.page;
        this.getInfo();
      },
      handleLoadMore: async function () {
          this.loading = true;
          this.page++;
          await this.getMoreInfo();
          this.loading = false;
      },
      getTotalItems: function () {
        this.$http.get('/Novidades/GetCount').then(res => {
          this.totalItems = res.data;
        }).catch(err => {
          this.$swal('error', err.message, 'Error');
        })
      },
      ordering: function (value) {
        this.page = 1;
        if (value === "d") this.order = "desc";
        else if (value === "a") this.order = "asc";
        this.getInfo();
      },
      getInfo: async function () {
        this.loading = true;
        this.$http.get('/Novidades/GetList?page=' + this.page + '&order=' + this.order).then(res => {
          this.desserts = res.data.map(x => {
            return { date: x.data, title: x.descricao };
          });
          if (this.desserts.length <= 0) this.desserts.push({date: "---", title: "Nenhuma novidade cadastrada!"})
          this.loading = false;
        }).catch(err => {
          if (this.desserts.length <= 0) this.desserts.push({date: "---", title: "Nenhuma novidade cadastrada!"})
          this.loading = false;
          this.$swal('error', err.message, 'Error');
        });
      },
      getMoreInfo: async function () {
        this.loading = true;
        await this.$http.get('/Novidades/GetList?page=' + this.page + '&order=' + this.order).then(res => {
          let addmore = res.data.map(x => {
            return { date: x.data, title: x.descricao };
          });
          this.desserts = this.desserts.concat(addmore);
          if (this.desserts.length <= 0) this.desserts.push({date: "---", title: "Nenhuma novidade cadastrada!"})
          this.loading = false;
        }).catch(err => {
          if (this.desserts.length <= 0) this.desserts.push({date: "---", title: "Nenhuma novidade cadastrada!"})
          this.loading = false;
          this.$swal('error', err.message, 'Error');
        });
      },
      translate: function (value) {
          let date = new moment(value).format('DD [de] MMMM [de] YYYY');
          let months = { 'January': 'Janeiro', 'February': 'Fevereiro', 'March': 'Março', 'April': 'Abril', 'May': 'Maio', 'June': 'Junho', 'July': 'Julho', 'August': 'Agosto', 'September': 'Setembro', 'October': 'Outubro', 'November': 'Novembro','December': 'Dezembro' };
          let str = date.split(' ');
          str[2] = months[str[2]];
          return str.join(' ');
      }
    },
    beforeMount: function () {
      this.getTotalItems();
    },
    mounted: function () {
      this.getInfo(1);
    },
    computed: {
        displaySeeMore: function () {
            return (this.desserts.length < this.totalItems)
        }
    }
  }
</script>

<style>
@import "../About.css";
</style>